<template>
  <div class="mt-3">
    <div
      v-if="
        show_id == 'casino' || show_id == 'main_casino' || show_id == 'showtv'
      "
      class="home_game_arrow home_game_casino home_game_maincasino"
      :class="[g_type == 'casino' ? 'g-flex-wrap' : '']"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="game_logo_img fw-bolder my-3"
          v-if="show_id == 'casino' || show_id == 'main_casino'"
        >
          <img
            src="@/assets/img/game_category/icon-live.png"
            alt=""
            style="height: 30px; width: 30px"
          />
          <span class="text_span"> {{ $t("home_text6").toLowerCase() }}</span>
        </div>

        <div class="game_logo_img fw-bolder my-3" v-else>
          <img
            src="@/assets/img/game_category/menu_show.png"
            alt=""
            style="height: 30px; widows: 30px"
          />
        </div>
        <!-- <div class="top_arrow" :class="[g_type == 'casino' ? 'd-none' : '']">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('maincasino', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('maincasino', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div> -->
      </div>

      <div
        v-if="show_id == 'casino' || show_id == 'main_casino'"
        class="maincasino_img"
      >
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'casino' ? false : true"
        >
          <swiper-slide
            v-for="l in c_s_list('allcasino')"
            :key="l"
            class="home_game_img main_img_casino"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                class="game_item"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div v-else>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide
            v-for="l in c_s_list('showtv')"
            :key="l"
            class="home_game_img"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div
      class="home_game_arrow home_game_sport"
      v-if="show_id == 'sport'"
      :class="[g_type == 'sport' ? 'g-flex-wrap' : '']"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/icon-sport.png"
            alt=""
            style="width: 28px; height: 28px"
          />
          <span class="text_span"> {{ $t("text_sport").toLowerCase() }}</span>
        </div>
      </div>

      <div class="home_game_sb">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <span class="text_span">SB</span>
          </div>
          <div class="top_arrow" :class="[g_type == 'sport' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('sb', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('sb', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'sport' ? false : true"
        >
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('web', 'sport', '', 'SB', 'sport')"
                class="game_item"
              >
                <img
                  v-lazy="
                    require('@/assets/img/sport/bt7_sport_SABASPORTS.png')
                  "
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide
            v-for="l in c_s_list('sport')"
            :key="l"
            class="home_game_img"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <!--     <div class="home_game_lucky">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <span class="text_span">Lucky</span>
          </div>
          <div class="top_arrow" :class="[g_type == 'sport' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('lucky', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('lucky', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'sport' ? false : true"
        >
          <swiper-slide
            v-for="l in temp_luck_sport"
            :key="l"
            class="home_game_img"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', '', 'Coming Soon', '', '')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sport/' + l + '.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div> -->
    </div>
    <div v-if="show_id == 'slot'">
      <div
        class="home_game_arrow home_game_slot_sub"
        :class="[
          'home_game_slot_' + j,
          sub_type != null && sub_type != '' ? 'g-flex-wrap' : '',
        ]"
        v-for="(j, m) in filterSearch"
        :key="m"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <img
              :src="
                j.toUpperCase().indexOf('A1-') != -1
                  ? require('@/assets/img/allinslot/logo/slot_a1_' +
                      j.split('-')[1].toLowerCase() +
                      '.png')
                  : j.toUpperCase().indexOf('GAMEX-') != -1
                  ? require('@/assets/img/img_gamex/logo/slot_gamex_' +
                      j.split('-')[1].toLowerCase() +
                      '.png')
                  : require('@/assets/img/game/' + j + '.png')
              "
              :title="j.toUpperCase()"
              style="width: 55px; height: 25px"
            />&nbsp;{{
              j.toUpperCase().indexOf("A1-") != -1
                ? j.split("-")[1].toUpperCase()
                : j.toUpperCase().indexOf("GAMEX-") != -1
                ? j.split("-")[1].toUpperCase()
                : j.toUpperCase()
            }}
          </div>
          <div class="top_arrow" v-if="sub_type == null || sub_type == ''">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('slot_' + j, 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('slot_' + j, 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="sub_type != null && sub_type != '' ? false : true"
        >
          <swiper-slide
            v-for="(l, k) in pp_list(j)"
            :key="k"
            class="home_game_img home_game_img_hot d-flex"
          >
            <div
              class="home_game_img_div w-100 c-pointer"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
            >
              <div :class="css_list" class="game_item">
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>

              <div class="mt-2">
                <p
                  style="
                    word-break: break-all;
                    white-space: pre-line;
                    height: 45px;
                    overflow-y: hidden;
                  "
                >
                  {{ l.gamename }}
                </p>
                <p
                  :class="[
                    random_arr(m, k) < 98 ? 'bg-new-green' : 'bg-new-red',
                  ]"
                  class="text-center text-white px-2 py-1 fs-375-14 r-num-color"
                >
                  <span>{{ random_arr(m, k) }}</span> %
                </p>
                <div class="d-flex justify-content-start align-items-center">
                  <img
                    src="@/assets/img/new_img/green_light.png"
                    style="width: 30px"
                    class="img_w-375-20"
                  />
                  <span class="ms-2 fs-375-14 text-start"
                    >{{ random_num() }}{{ $t("text_perple_play") }}</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <!-- <div class="w-25 mx-auto text-center w-lg-75" v-if="flag_slot == 0">
        <button class="w-100 btn btn-success main_color" @click="more()">
          <a>{{ $t("slot_text2") }}</a>
        </button>
      </div> -->
    </div>
    <div
      v-if="show_id == 'main_slot'"
      class="row home_game_arrow home_game_slot"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/icon-slot.png"
            alt=""
            style="width: 28px; height: 28px"
          />
          <span class="text_span"> {{ $t("home_text7").toLowerCase() }}</span>
        </div>

        <div class="top_arrow">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('slot', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('slot', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
      >
        <swiper-slide
          v-for="l in slot_list"
          :key="l"
          class="home_game_img main_img_slot"
        >
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="set_gametype(l.toUpperCase())"
              class="game_item"
            >
              <img
                :src="
                  l.indexOf('A1-') != -1
                    ? require('@/assets/img/allinslot/' +
                        l.split('-')[1] +
                        '.png')
                    : l.indexOf('GAMEX-') != -1
                    ? require('@/assets/img/img_gamex/' +
                        l.split('-')[1] +
                        '.png')
                    : require('@/assets/img/game/all/slot_' + l + '.png')
                "
                class="w-100"
              />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div
      v-if="show_id == 'fish'"
      class="home_game_arrow home_game_casino home_game_fish"
      :class="[g_type == 'fish' ? 'g-flex-wrap' : '']"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/icon-fish.png"
            alt=""
            style="height: 30px; width: 30px"
          />
          <span class="text_span"> {{ $t("text_main12").toLowerCase() }}</span>
        </div>
        <div class="top_arrow" :class="[g_type == 'fish' ? 'd-none' : '']">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('fish', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('fish', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
        :allowTouchMove="g_type == 'fish' ? false : true"
      >
        <swiper-slide
          class="home_game_img"
          v-for="l in c_s_list('fish')"
          :key="l"
        >
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
              class="game_item"
            >
              <img v-lazy="l.iconName" class="w-100" />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="show_id == 'providers'">
      <div class="home_game_arrow home_game_slot home_game_allcasino">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <svg
              id="menu_icon_livecasino"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="40.116"
              height="40.068"
              viewBox="0 0 40.116 40.068"
              class="svg_w"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectangle_586"
                    data-name="Rectangle 586"
                    width="40.116"
                    height="40.068"
                    fill="#FFF"
                  ></rect>
                </clipPath>
              </defs>
              <g id="Group_20" data-name="Group 20" transform="translate(0 0)">
                <path
                  id="Path_24"
                  data-name="Path 24"
                  d="M3.209,0A3.208,3.208,0,0,0,0,3.209V35.3a3.208,3.208,0,0,0,3.209,3.209H25.674A3.208,3.208,0,0,0,28.883,35.3V3.209A3.208,3.208,0,0,0,25.674,0ZM31.7,1.088a6.335,6.335,0,0,1,.388,2.121V35.3a6.4,6.4,0,0,1-1.884,4.538l1.187.188a3.213,3.213,0,0,0,3.673-2.67l5.006-31.7a3.209,3.209,0,0,0-2.667-3.673ZM14.442,8.023a1.565,1.565,0,0,1,.878.3l.075.018c2.848,2.319,8.675,8.065,8.675,11.722a5.616,5.616,0,0,1-5.616,5.616,5.551,5.551,0,0,1-3.419-1.194,8.142,8.142,0,0,0,3.721,4.459v0a.8.8,0,0,1-.3,1.545H10.43a.8.8,0,0,1-.3-1.545v0A8.136,8.136,0,0,0,13.85,24.48a5.551,5.551,0,0,1-3.419,1.194,5.616,5.616,0,0,1-5.616-5.616c0-3.657,5.826-9.4,8.675-11.722l.075-.018a1.565,1.565,0,0,1,.878-.3"
                  transform="translate(0 0)"
                  fill="#FFF"
                ></path>
              </g>
            </svg>
            <span class="text_span"> {{ $t("home_text6").toLowerCase() }}</span>
          </div>

          <div class="top_arrow">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('allcasino', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('allcasino', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide
            v-for="l in c_s_list('allcasino')"
            :key="l"
            class="home_game_img"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="home_game_arrow home_game_slot home_game_allslot">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 42 37.134"
            >
              <defs>
                <clipPath id="clipPath">
                  <rect
                    id="Rectangle_585"
                    data-name="Rectangle 585"
                    width="42"
                    height="37.134"
                    fill="#FFF"
                  ></rect>
                </clipPath>
              </defs>
              <g id="menu_icon_slots" transform="translate(0 0)">
                <g
                  id="Group_18"
                  data-name="Group 18"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M16.169,0A22.1,22.1,0,0,0,8.178,1.614H4.867A3.228,3.228,0,0,0,1.638,4.843V33.9a1.615,1.615,0,1,0-.045,3.229H30.7a1.615,1.615,0,0,0,.045-3.229H30.7V4.843a3.228,3.228,0,0,0-3.23-3.229H24.158A22.094,22.094,0,0,0,16.169,0m22.6,4.843a3.227,3.227,0,0,0-1.614,6.023v8.508a1.589,1.589,0,0,1-1.614,1.614H33.928v3.229h1.614a4.868,4.868,0,0,0,4.843-4.843V10.863a3.225,3.225,0,0,0-1.614-6.019M8.1,6.457H24.241a3.228,3.228,0,0,1,3.229,3.229v6.459a3.228,3.228,0,0,1-3.229,3.229H8.1a3.228,3.228,0,0,1-3.229-3.229V9.686A3.228,3.228,0,0,1,8.1,6.457m0,9.688h3.229V9.688H8.1Zm6.459,0h3.229V9.688H14.554Zm6.457,0h3.229V9.688H21.012ZM8.1,24.217H24.241v6.457H8.1Z"
                    transform="translate(0 -0.001)"
                    fill="#FFF"
                  ></path>
                </g>
              </g>
            </svg>
            <span class="text_span"> {{ $t("home_text7").toLowerCase() }}</span>
          </div>

          <div class="top_arrow">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('allslot', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('allslot', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
        >
          <swiper-slide v-for="l in slot_list" :key="l" class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="set_gametype(l.toUpperCase())"
                class="game_item"
              >
                <img
                  :src="
                    l.indexOf('A1-') != -1
                      ? require('@/assets/img/allinslot/' +
                          l.split('-')[1] +
                          '.png')
                      : l.indexOf('GAMEX-') != -1
                      ? require('@/assets/img/img_gamex/' +
                          l.split('-')[1] +
                          '.png')
                      : require('@/assets/img/game/all/slot_' + l + '.png')
                  "
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div v-if="show_id == 'hot'">
      <div
        class="home_game_arrow home_game_casino home_game_hot home_game_slot_gamex g-flex-wrap"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <img
              src="@/assets/img/game_category/ic2_hot.png"
              alt=""
              style="width: 28px; height: 28px"
            />
            <span class="text_span">
              {{ $t("text_main11").toLowerCase() }}</span
            >
          </div>

          <!-- <div class="top_arrow" :class="[g_type == 'hot' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('hot', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('hot', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div> -->
        </div>

        <div class="live_casino">
          <div class="live_casino_card">
            <div class="game_slider_card home_game_img_hot">
              <div
                v-for="(l, k) in c_s_list('hot')"
                :key="k"
                class="filter_card game_item text-center home_slot_game"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
              >
                <div
                  class="filter_card1"
                  v-lazy:background-image="
                    'http://gameweb.metaversement.io/' + l.image
                  "
                ></div>
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>

                <div class="mt-2">
                  <p
                    style="
                      word-break: break-all;
                      white-space: pre-line;
                      height: 45px;
                      overflow-y: hidden;
                    "
                  >
                    {{ l.gamename }}
                  </p>
                  <p
                    :class="[
                      random_arr(0, k) < 98 ? 'bg-new-green' : 'bg-new-red',
                    ]"
                    class="text-center text-white px-2 py-1 fs-375-14 r-num-color"
                  >
                    <span>{{ random_arr(0, k) }}</span> %
                  </p>
                  <div class="d-flex justify-content-start align-items-center">
                    <img
                      src="@/assets/img/new_img/green_light.png"
                      style="width: 30px"
                      class="img_w-375-20"
                    />
                    <span class="ms-2 fs-375-14 text-start"
                      >{{ random_num() }}{{ $t("text_perple_play") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="false"
        >
          <swiper-slide
            v-for="(l, k) in c_s_list('hot')"
            :key="k"
            class="home_game_img home_game_img_hot d-flex"
          >
            <div
              class="home_game_img_div w-100 c-pointer"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
            >
              <div :class="css_list" class="game_item">
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>

              <div class="mt-2">
                <p
                  style="
                    word-break: break-all;
                    white-space: pre-line;
                    height: 45px;
                    overflow-y: hidden;
                  "
                >
                  {{ l.gamename }}
                </p>
                <p
                  :class="[
                    random_arr(0, k) < 98 ? 'bg-new-green' : 'bg-new-red',
                  ]"
                  class="text-center text-white px-2 py-1 fs-375-14 r-num-color"
                >
                  <span>{{ random_arr(0, k) }}</span> %
                </p>
                <div class="d-flex justify-content-start align-items-center">
                  <img
                    src="@/assets/img/new_img/green_light.png"
                    style="width: 30px; height: 30px"
                    class="img_w-375-20"
                  />
                  <span class="ms-2 fs-375-14 text-start"
                    >{{ random_num() }}{{ $t("text_perple_play") }}</span
                  >
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper> -->
      </div>

      <!-- <div v-if="g_type == 'hot'">
        <three_random_rank :show_rank_id="'recentbig'" />
        <three_random_rank :show_rank_id="'fish'" />
        <three_random_rank :show_rank_id="'rtp'" />
      </div> -->
    </div>
    <div
      v-if="show_id == 'lotto'"
      class="home_game_arrow home_game_casino home_game_lotto"
      :class="[g_type == 'lotto' ? 'g-flex-wrap' : '']"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/icon-lotto.png"
            alt=""
            style="height: 30px; width: 30px"
          />
          <span class="text_span"> {{ $t("text_lotto").toLowerCase() }}</span>
        </div>
        <!-- <div class="top_arrow" :class="[g_type == 'lotto' ? 'd-none' : '']">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('lotto', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('lotto', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div> -->
      </div>
      <div>
        <div class="swiper-wrapper">
          <div class="swiper-slide home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'lottery', 'lobby', 'BBIN', 'lottery')"
                class="game_item"
              >
                <img src="@/assets/img/game/lottery/10.png" class="w-100" />
                <!-- <span
                  class="position-absolute start-0 bottom-0 text-center w-100"
                  >Lotto Lobby</span
                > -->
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="show_id == 'chess'"
      class="home_game_arrow home_game_casino home_game_chess"
      :class="[g_type == 'chess' ? 'g-flex-wrap' : '']"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/icon-g-chess.png"
            alt=""
            style="width: 35px"
          />
          <span class="text_span"> {{ $t("text_chess").toLowerCase() }}</span>
        </div>
      </div>

      <!-- <div class="home_game_66">
        <div class="d-flex justify-content-between align-items-center">
          <div class="game_logo_img fw-bolder my-3">
            <span class="text_span"> 6666 Poker</span>
          </div>
          <div class="top_arrow" :class="[g_type == 'chess' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('66', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('66', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'chess' ? false : true"
        >
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10000', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_txh.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'sicbo_10003', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_dic.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'tongzi_10003', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_ttz.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'niu_10003', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_cow.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'baccarat_10007', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_bac.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10014', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_royal.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div> -->

      <div class="home_game_ky">
        <div
          class="d-flex justify-content-between align-items-center"
          :class="[g_type == 'chess' ? 'mt-3' : '']"
        >
          <div class="game_logo_img fw-bolder my-3">
            <span class="text_span"> {{ $t("text_kychess") }}</span>
          </div>
          <div class="top_arrow" :class="[g_type == 'chess' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('ky', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('ky', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>

        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'chess' ? false : true"
        >
          <swiper-slide
            class="home_game_img"
            v-for="l in c_s_list('ky')"
            :key="l"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="home_game_leg">
        <div
          class="d-flex justify-content-between align-items-center"
          :class="[g_type == 'chess' ? 'mt-3' : '']"
        >
          <div class="game_logo_img fw-bolder my-3">
            <span class="text_span"> {{ $t("text_legchess") }}</span>
          </div>
          <div class="top_arrow" :class="[g_type == 'chess' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('leg', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('leg', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'chess' ? false : true"
        >
          <swiper-slide
            class="home_game_img"
            v-for="l in c_s_list('leg')"
            :key="l"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="home_game_jdb">
        <div
          class="d-flex justify-content-between align-items-center"
          :class="[g_type == 'chess' ? 'mt-3' : '']"
        >
          <div class="game_logo_img fw-bolder my-3">
            <span class="text_span"> {{ $t("text_jdbchess") }}</span>
          </div>
          <div class="top_arrow" :class="[g_type == 'chess' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('jdb', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('jdb', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'chess' ? false : true"
        >
          <swiper-slide
            class="home_game_img"
            v-for="l in c_s_list('jdbchess')"
            :key="l"
          >
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="
                  game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)
                "
                class="game_item"
              >
                <img v-lazy="l.iconName" class="w-100" />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div
      v-if="show_id == 'area'"
      class="home_game_arrow home_game_casino home_game_area"
      :class="[g_type == 'area' ? 'g-flex-wrap' : '']"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/icon-area.png"
            alt=""
            style="width: 35px"
          />
          <span class="text_span"> {{ $t("text_area").toLowerCase() }}</span>
        </div>
        <div class="top_arrow" :class="[g_type == 'area' ? 'd-none' : '']">
          <div class="left_right">
            <button class="btn_arrow" @click="turn('area', 'left')">
              <span role="img" aria-label="left"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="left"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                  ></path>
                </svg>
              </span></button
            ><button class="btn_arrow" @click="turn('area', 'right')">
              <span role="img" aria-label="right"
                ><svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="right"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
      <swiper
        :loop="swiper_casino_options.loop"
        :speed="swiper_casino_options.speed"
        :slidesPerView="swiper_casino_options.slidesPerView"
        :spaceBetween="swiper_casino_options.spaceBetween"
        :breakpoints="swiper_casino_options.breakpoints"
        :navigation="swiper_casino_options.navigation"
        :allowTouchMove="g_type == 'area' ? false : true"
      >
        <swiper-slide
          class="home_game_img"
          v-for="l in c_s_list('area')"
          :key="l"
        >
          <div class="home_game_img_div">
            <div
              :class="css_list"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
              class="game_item"
            >
              <img v-lazy="l.iconName" class="w-100" />
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div
      v-if="show_id == 'guess'"
      class="home_game_arrow home_game_casino g-flex-wrap"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/menu_favorite.png"
            alt=""
            style="width: 24px; height: 24px"
          />
          <span class="text_span"> {{ $t("text_favorites") }}</span>
        </div>
      </div>

      <div class="live_casino">
        <div class="live_casino_card">
          <div class="game_slider_card">
            <div
              v-for="l in c_s_list('guess')"
              :key="l"
              class="filter_card game_item"
              @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
            >
              <div
                class="filter_card1"
                v-lazy:background-image="l.iconName"
              ></div>
              <!-- <div class="mask position-absolute top-0 start-0"></div> -->
              <div class="game-actions">
                <span class="btn-play">{{ $t("home_text1") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="show_id == 'poker'"
      class="home_game_arrow home_game_casino home_game_poker"
      :class="[g_type == 'poker' ? 'g-flex-wrap' : '']"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="game_logo_img fw-bolder my-3">
          <img
            src="@/assets/img/game_category/poker.png"
            alt=""
            style="width: 35px; height: 35px"
          />
          <span class="text_span"> {{ $t("text_poker_game_1") }}</span>
        </div>
      </div>
      <div class="home_game_poker1">
        <div
          class="d-flex justify-content-between align-items-center"
          :class="[g_type == 'poker' ? 'mt-3' : '']"
        >
          <div class="game_logo_img fw-bolder my-3">
            <img
              src="@/assets/img/game_category/poker1.png"
              alt=""
              style="height: 30px; width: 30px"
            />
            <div
              class="d-flex justify-content-start align-items-center flex-wrap"
            >
              <span class="text_span"> {{ $t("text_texas_poker") }}</span>
              <span class="text_span">
                {{ $t("poker_text1").toLowerCase() }}
              </span>
            </div>
          </div>
          <div class="top_arrow" :class="[g_type == 'poker' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('poker1', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('poker1', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'poker' ? false : true"
        >
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10000', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_txh.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10001', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_txh.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10002', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_txh.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10003', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_txh.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="home_game_poker2">
        <div
          class="d-flex justify-content-between align-items-center"
          :class="[g_type == 'poker' ? 'mt-3' : '']"
        >
          <div class="game_logo_img fw-bolder my-3">
            <img
              src="@/assets/img/game_category/poker2.png"
              alt=""
              style="width: 50px"
            />
            <div
              class="d-flex justify-content-start align-items-center flex-wrap"
            >
              <span class="text_span"> {{ $t("poker_text3") }}</span>
              <span
                class="text_span"
                style="word-break: keep-all; line-height: 25px"
              >
                {{ $t("poker_text2").toLowerCase() }}
              </span>
            </div>
          </div>
          <div class="top_arrow" :class="[g_type == 'poker' ? 'd-none' : '']">
            <div class="left_right">
              <button class="btn_arrow" @click="turn('poker2', 'left')">
                <span role="img" aria-label="left"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="left"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"
                    ></path>
                  </svg>
                </span></button
              ><button class="btn_arrow" @click="turn('poker2', 'right')">
                <span role="img" aria-label="right"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>

        <swiper
          :loop="swiper_casino_options.loop"
          :speed="swiper_casino_options.speed"
          :slidesPerView="swiper_casino_options.slidesPerView"
          :spaceBetween="swiper_casino_options.spaceBetween"
          :breakpoints="swiper_casino_options.breakpoints"
          :navigation="swiper_casino_options.navigation"
          :allowTouchMove="g_type == 'poker' ? false : true"
        >
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10083', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_royal.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10084', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_royal.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10085', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_royal.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="home_game_img">
            <div class="home_game_img_div">
              <div
                :class="css_list"
                @click="game('', 'poker', 'texas_10086', 'TEXAS', 'slot')"
                class="game_item"
              >
                <img
                  v-lazy="require('@/assets/img/sg_img/mainimg_game_royal.png')"
                  class="w-100"
                />
                <div class="game-actions">
                  <span class="btn-play">{{ $t("home_text1") }}</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive } from "vue";
import { mapActions, mapGetters } from "vuex";
//引入swiper
import SwiperCore, { Autoplay, EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
//设置swiper
SwiperCore.use([Autoplay, EffectCoverflow, Navigation]);

// import three_random_rank from "./three_random_rank.vue";
export default {
  props: { show_id: String, allinslot: Array, gamexslot: Array },
  setup() {
    //指定swiper的设置
    let swiper_casino_options = reactive({
      loop: false,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
    });

    return {
      swiper_casino_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    // three_random_rank,
  },
  data() {
    return {
      css_list: "position-relative",
      flag_slot: 0,
      temp_luck_sport: [31, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30],
      random_list: [],
    };
  },
  methods: {
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      var action_val = t != "allcasino" && t != "sport" ? "GameLobby" : "";

      var gametype =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[0]
          : t.toUpperCase().indexOf("GAMEX-") != -1
          ? t.toUpperCase().split("-")[0]
          : t.toUpperCase() == "JDBCHESS"
          ? "JDB"
          : t;
      var type =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[1]
          : t.toUpperCase().indexOf("GAMEX-") != -1
          ? t.toUpperCase().split("-")[1]
          : t.toUpperCase() == "JDBCHESS"
          ? "Card"
          : "all";

      let param = {
        action: action_val,
        body: {
          gametype: gametype,
          type: type,
          uid: localStorage.getItem("uid"),
        },
      };
      this.getGameList(param);
    },
    set_gametype: function (e) {
      this.$router.push("/slot/slot/" + e);
    },
    game: function (device, mode, id, type, kind) {
      this.$parent.game(device, mode, id, type, kind);
    },
    turn(id, e) {
      if (e == "left") {
        document
          .querySelector(".home_game_" + id + " .swiper-button-prev")
          .click();
      } else {
        document
          .querySelector(".home_game_" + id + " .swiper-button-next")
          .click();
      }
    },
    change_page(val) {
      if (val == "slot") {
        this.flag_slot = 0;
        this.all_game["slot"].forEach((v) => {
          this.gamelist(v);
        });
        this.gamexslot.forEach((v) => {
          this.gamelist(v.toUpperCase());
        });
        // this.allinslot.forEach((v) => {
        //   this.gamelist(v.toUpperCase());
        // });
      } else if (val == "casino") {
        this.gamelist("allcasino");
      } else if (val == "sport") this.gamelist("sport");
      else if (val == "showtv") {
        this.gamelist("EVOPREMIUM");
      } else if (val == "providers") {
        this.all_game["slot"].forEach((v) => {
          this.gamelist(v);
        });
        // this.allinslot.forEach((v) => {
        //   this.gamelist(v.toUpperCase());
        // });
        this.gamelist("allcasino");
      } else if (val == "hot") {
        this.gamelist("HOT");
      } else if (val == "main_casino") {
        this.gamelist("allcasino");
      } else if (val == "chess") {
        this.gamelist("ky");
        this.gamelist("leg");
        this.gamelist("jdbchess");
      } else if (val == "fish") {
        this.gamelist("fish");
      } else if (val == "guess") {
        this.gamelist("guess");
      } else if (val == "area") {
        this.gamelist("area");
      }
    },
    more: function () {
      this.flag_slot = 1;
    },
    random_num() {
      return Math.ceil(Math.random() * 99849 + 150);
    },
    set_random(count, layer) {
      var arr = [];
      for (var i = 0; i < layer; i++) {
        var o = [];
        for (var j = 0; j < count; j++) {
          var aa = (Math.random() * (115 - 95) + 95).toFixed(2);
          o.push(aa);
        }
        arr.push(o);
      }
      this.random_list = arr;
    },
  },
  watch: {
    show_id(newval) {
      this.change_page(newval);
    },
  },
  computed: {
    ...mapGetters(["all_game", "list"]),
    g_type() {
      if (this.$route.params.type != null) return this.$route.params.type;
      else return "";
    },
    sub_type() {
      if (this.$route.params.sub_type != null)
        return this.$route.params.sub_type;
      else return "";
    },
    slot_list() {
      if (this.sub_type != null && this.sub_type != "")
        return [this.sub_type.toLowerCase()];
      else return this.gamexslot.concat(this.all_game["slot"]);
    },
    c_s_list() {
      return function (id) {
        this.list;
        if (id == "showtv") id = "evopremium";
        if (id == "jdbchess")
          return JSON.parse(localStorage.getItem("jdb_card_gamelist"));
        if (
          id == "hot" &&
          localStorage.getItem("hot_all_gamelist") != null &&
          localStorage.getItem("hot_all_gamelist") != ""
        ) {
          if (this.g_type == "hot")
            return JSON.parse(localStorage.getItem("hot_all_gamelist"));
          else
            return JSON.parse(localStorage.getItem("hot_all_gamelist")).slice(
              0,
              18
            );
        } else return JSON.parse(localStorage.getItem(id + "_all_gamelist"));
      };
    },
    pp_list() {
      return function (jj) {
        this.list;
        if (this.sub_type != null && this.sub_type != "") {
          if (jj.toUpperCase().indexOf("A1-") != -1)
            return JSON.parse(
              localStorage.getItem(
                "a1_" + jj.split("-")[1].toLowerCase() + "_gamelist"
              )
            );
          else if (jj.toUpperCase().indexOf("GAMEX-") != -1)
            return JSON.parse(
              localStorage.getItem(
                "gamex_" + jj.split("-")[1].toLowerCase() + "_gamelist"
              )
            );
          else
            return JSON.parse(
              localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
            );
        } else {
          try {
            if (this.list.length <= 0) {
              return [];
            } else {
              if (jj.indexOf("A1-") != -1)
                return JSON.parse(
                  localStorage.getItem(
                    "a1_" + jj.split("-")[1].toLowerCase() + "_gamelist"
                  )
                ).slice(0, 15);
              else if (jj.indexOf("GAMEX-") != -1)
                return JSON.parse(
                  localStorage.getItem(
                    "gamex_" + jj.split("-")[1].toLowerCase() + "_gamelist"
                  )
                ).slice(0, 15);
              else
                return JSON.parse(
                  localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
                ).slice(0, 15);
            }
          } catch {
            return [];
          }
        }
      };
    },
    filterSearch() {
      return this.gamexslot.concat(this.all_game["slot"]);
    },
    random_arr() {
      var new_arr_2 = this.random_list;
      return function (id, index) {
        if (new_arr_2 != null && new_arr_2 != "") {
          return new_arr_2[id].slice(index, index + 1)[0];
        }
      };
    },
  },
  mounted() {},
  created() {
    this.set_random(200, 10);
    this.change_page(this.show_id);
  },
};
</script>
