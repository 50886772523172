<template>
  <div>
    <gamecateview />

    <game_page :m_show_id="'hot'" />
    <hero_rank />
    <dt_rank />
    <game_page :m_show_id="'main_slot'" />
    <game_page :m_show_id="'main_casino'" />
    <!-- 
    <game_page :m_show_id="'sport'" />
    <game_page :m_show_id="'poker'" />
    <game_page :m_show_id="'fish'" />
    <game_page :m_show_id="'chess'" />
    <game_page :m_show_id="'area'" />
    <game_page :m_show_id="'lotto'" /> -->

    <rank />
    <dwrank />
    <qaview />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import game_page from "./game_page.vue";
import gamecateview from "./game_category.vue";
import rank from "./rank.vue";
import dwrank from "./dw_rank.vue";
import qaview from "./common_qa.vue";
import hero_rank from "./hero_rank.vue";
import dt_rank from "./dragontiger_rank.vue";
export default {
  name: "main",
  data() {
    return {
      open_intro: false,
      code: Cookies.get("code"),
    };
  },
  computed: {
    g_type() {
      if (this.$route.params.type != null) {
        if (this.$route.params.type.indexOf("A1-") != -1)
          return this.$route.params.type.split("-")[1];
        else return this.$route.params.type;
      } else return "";
    },
  },
  methods: {
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
  },
  created() {},
  watch: {},
  mounted() {},
  components: {
    game_page,
    gamecateview,
    rank,
    dwrank,
    qaview,
    hero_rank,
    dt_rank,
  },
};
</script>
